<template>
    <div
      class="uitleg-project"
      :style="{ height: initialHeightRef + 'px', marginTop: marginTopRef + 'px', marginBottom: marginBottomRef + 'px' }"
    >
      <div class="card-background">
        <div class="textWrapper">
          <div v-if="titleContentRef" class="main-titel" v-html="titleContentRef"></div>
          <div v-if="subTitleContentRef" class="uitlegtitel" v-html="subTitleContentRef"></div>
          <div v-if="textContentRef" class="regularText" v-html="textContentRef"></div>
          <button v-if="buttonRef" class="custom-button" @click="setRooter(buttonRef)">
            {{ buttonRef }}
          </button>
        </div>
      </div>
    </div>
  </template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { router } from '@/router';

export default defineComponent({
    name: 'TextElement',
    props: {
        title: {
            type: String,
            required: false
        },
        subTitle: {
            type: String,
            required: false
        },
        textContent: {
            type: String,
            required: false
        },
        initialHeight: {
            type: Number,
            required: false,
            default: 676
        },
        marginTop: {
            type: Number,
            required: false,
            default: 50
        },
        marginBottom: {
            type: Number,
            required: false,
            default: 50
        },
        button: {
            type: String,
            required: false,
        }
        

    },
    setup(props) {
        const setRooter = (name: string) => {
            if (name==="SPEEL HET SPEL") {
                router.push("/Game");
            } else if (name==="NAAR DE TIPS"){
                router.push("/Tips");
            } 
        };

        return {
            setRooter,
            titleContentRef: props.title,
            subTitleContentRef: props.subTitle,
            textContentRef: props.textContent,
            initialHeightRef: props.initialHeight,
            marginTopRef: props.marginTop,
            marginBottomRef: props.marginBottom,
            buttonRef: props.button
        };
    }
})
</script>

<style>
.uitleg-project {
    width: 580px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.uitleg-project .card-background {
    background-color: #ffffff;
    border-radius: 18px;
    background-size: 100% 100%;
    height: 100%;
    width: 100%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.uitleg-project .textWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: 27px;
    height: 100%;
    width: 500px;
}


.uitleg-project .main-titel {
    color: #f15a24;
    font-family: SUSE;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    line-height: normal;
    text-align: left;
    letter-spacing: 0;

    width: 500px;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 50px;
    margin-bottom: 20px;
}

.uitleg-project .regularText {
    color: #086f6f;
    font-family: "SUSE";
    font-size: 16px;
    font-weight: 500;
    left: 57px;
    letter-spacing: 0;
    line-height: normal;
    text-align: left;

    width: 500px;
    margin-left: 40px;
    margin-right: 40px;
}

.uitleg-project .uitlegtitel {
    color: #086f6f;
    font-family: SUSE;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    letter-spacing: 0;
    line-height: normal;

    width: 500px;
    height: auto;
    margin-left: 40px;
    margin-left: 40px;
    margin-bottom: 0px;
}

.uitleg-project .custom-button {
    position: relative;
    width: 180px;
    left: 50%;
    transform: translate(-50%, 0);
    height: 42.429px;
    flex-shrink: 0;
    border-radius: 18px;
    border: 2px solid #f15a24;
    background: #f15a24;
    /* Initial background color */
    color: #ffffff;
    /* Text color matching the border */
    font-family: SUSE, sans-serif;
    /* Optional: Set your font family */
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
    /* Smooth transition for background */
}

.custom-button:hover {
    background-color: #ce5022;
    border: 2px solid #ce5022;
}
@media (max-width: 1140px) {
    .uitleg-project {
        margin-top: 0px;
    }

}

@media (max-width: 700px) {
    .uitleg-project {
        width: 300px;
        height: auto;
        /* Adjust height to fit content better on smaller screens */
    }

    .uitleg-project .card-background {
        height: auto;
        /* Adjust height to maintain responsiveness */
        width: 100%;
    }

    .uitleg-project .textWrapper {
        width: 90%;
        /* Reduce width to fit better */
    }

    .uitleg-project .main-titel {
        font-size: 20px;
        margin-left: 20px;
        width: 90%;
        /* Reduce width to fit better */
        margin-right: 20px;
        margin-top: 20px;

    }

    .uitleg-project .uitlegtitel {
        font-size: 12px;
        width: 90%;
        /* Reduce width to fit better */
        height: auto;
        margin-left: 20px;
        margin-right: 20px;
    }

    .uitleg-project .regularText {
        font-size: 10px;
        width: 90%;
        /* Reduce width to fit better */
        height: auto;
        margin-top: 0%;
        margin-left: 20px;
        margin-right: 20px;
    }
}
</style>
