<template>
  <LogosComponent ref="logoscomp" />
  <div id="unity-container" class="unity-desktop">
    <canvas id="unity-canvas" width="1280" height="720" tabindex="-1"></canvas>
    <div id="unity-loading-bar">
      <div id="unity-progress-bar-empty">
        <div id="unity-progress-bar-full"></div>
      </div>
    </div>
    <div id="unity-warning"></div>
    <div id="unity-footer">
      <!-- <button @click="onFullscreenButtonClick" id="fullscreenButton">
        Fullscreen
      </button> -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import LogosComponent from "./LogosComponent.vue";
import { on } from "events";
import { router } from "@/router";
export default defineComponent({
  components: {
    LogosComponent,
  },
  setup() {
    const logoscomp = ref<InstanceType<typeof LogosComponent> | null>(null);

    onMounted(() => {

      window.addEventListener("message", handleUnityMessage);

      const container = document.querySelector("#unity-container")!;
      const canvas = document.querySelector(
        "#unity-canvas"
      ) as HTMLCanvasElement;
      const loadingBar = document.querySelector("#unity-loading-bar")!;
      const progressBarFull = document.querySelector(
        "#unity-progress-bar-full"
      )!;
      /* const fullscreenButton = document.querySelector("#fullscreenButton")!; */
      const warningBanner = document.querySelector("#unity-warning")!;

      // Shows a temporary message banner/ribbon for a few seconds, or
      // a permanent error message on top of the canvas if type=='error'.
      function unityShowBanner(msg: string, type: string) {
        function updateBannerVisibility() {
          (warningBanner as HTMLElement).style.display = warningBanner.children
            .length
            ? "block"
            : "none";
        }
        const div = document.createElement("div");
        div.innerHTML = msg;
        warningBanner.appendChild(div);
        if (type === "error") {
          div.style.background = "red";
          div.style.padding = "10px";
        } else {
          if (type === "warning") div.style.background = "yellow";
          div.style.padding = "10px";
          setTimeout(() => {
            warningBanner.removeChild(div);
            updateBannerVisibility();
          }, 5000);
        }
        updateBannerVisibility();
      }
      const logosElement = document.querySelector(".logos");

      var buildUrl = "/Build";
      var loaderUrl = buildUrl + "/EndOfDay5.loader.js";
      var config = {
        dataUrl: buildUrl + "/EndOfDay5.data",
        frameworkUrl: buildUrl + "/EndOfDay5.framework.js",
        codeUrl: buildUrl + "/EndOfDay5.wasm",
        streamingAssetsUrl: "StreamingAssets",
        companyName: "DefaultCompany",
        productName: "My project",
        productVersion: "0.1.0",
        showBanner: unityShowBanner,
      };

      if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
        // Mobile device style
        const meta = document.createElement("meta");
        meta.name = "viewport";
        meta.content =
          "width=device-width, height=device-height, initial-scale=1.0, user-scalable=no, shrink-to-fit=yes";
        document.getElementsByTagName("head")[0].appendChild(meta);
        container.className = "unity-mobile";
        canvas.className = "unity-mobile";
        onFullscreenButtonClick();
      } else {
        // Desktop style
        /* canvas.style.width = "1280px";
        canvas.style.height = "720px"; */
      }

      (loadingBar as HTMLElement).style.display = "block";

      const script = document.createElement("script");
      script.src = loaderUrl;
      script.onload = () => {
        window["createUnityInstance"](canvas, config, (progress: number) => {
          (progressBarFull as HTMLElement).style.width = 100 * progress + "%";
        })
          .then((unityInstance: any) => {
            (loadingBar as HTMLElement).style.display = "none";
            /* (fullscreenButton as HTMLElement).onclick = () => {
              unityInstance.SetFullscreen(1);
            }; */
          })
          .catch((message: string) => {
            alert(message);
          });
      };

      document.body.appendChild(script);
    });
    onUnmounted(() => {
      window.removeEventListener("message", handleUnityMessage);
    });
    const handleUnityMessage = (event: MessageEvent) => {
      console.log(event.data);
      if (event.data.type === "unityLog") {
        if (event.data.message === "HideLogos") {
          logoscomp.value?.hideComponent();
        }
        if (event.data.message === "FullScreen") {
          onFullscreenButtonClick();
        }
        if (event.data.message === "Return") {
         
            router.push("/" );

        }
        if (event.data.message === "SeeTips") {

            window.open("/Tips", "_blank");
        }


      }
    };
    
    const onFullscreenButtonClick = () => {
      const canvas = document.querySelector(
        "#unity-canvas"
      ) as HTMLCanvasElement;
      canvas.requestFullscreen();
      if (document.fullscreenElement) {
        document.exitFullscreen();
      }
    };


    return { onFullscreenButtonClick, logoscomp };
  },
});
</script>

<style scoped>
#unity-container {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #231F20;
}

#unity-canvas {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: calc(100vw * 9 / 16);
  /* Maintain a 16:9 aspect ratio */
  max-height: 100%;
  transform: translate(-50%, -50%);

}

#unity-loading-bar {
  position: absolute;
  width: 100%;
  /* Adjust this to your preference */
  height: 30px;
  /* Adjust the height as needed */
  background-color: #ccc;
  /* Background for the empty progress bar */
  top: 50%;
  /* Position it near the bottom of the container */
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
}

#unity-progress-bar-empty {
  width: 100%;
  height: 100%;
  background-color: #333;
  /* Darker background for better contrast */
  border-radius: 5px;
}

#unity-progress-bar-full {
  width: 0;
  height: 100%;
  background-color: #ffffff;
  /* Green progress indicator */
  border-radius: 5px;
  transition: width 0.3s ease;
  /* Smooth transition */
}
</style>
