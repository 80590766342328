<template>
  <div class="tipsPage">
    <div class="gamebutton">
      <div class="button-text" @click="setRooter('Game')">Speel het spel</div>
    </div>
    <div class="toptext">
      <div class="title">Learning cards</div>
      <div class="subTitle">
        16 tips om de transitie naar PaaS haalbaar te maken
      </div>
      <div class="ExplainText" v-html="AboutTipsText"></div>
    </div>
    <div class="tips">
      <div class="tip-object" v-for="(tip, index) in tips" :key="index">
        <TipObject :title="tip.title" :description="tip.description" :textContent="tip.text" :image="tip.image" />
      </div>
    </div>
    <div class="divider"></div>

    <!-- Divider Line -->

    <div class="bottomContainer">
      <div class="textContainer">
        <div class="bottomtitle">
          Over Circular Machine Building en de businessgame
        </div>
        <div class="text" v-html="AboutBottomText"></div>
      </div>
      <div class="containerContainer">
        <div class="textContainer">
          <div class="bottomtitle">
            Over het businessmodel product-as-a-service
          </div>

          <div class="text" v-html="ContactSirrisText"></div>
        </div>
        <div class="textContainer">
          <div class="bottomtitle">
            Over de circulaire ambities van Vlaanderen
          </div>

          <div class="text" v-html="CirculairVlaanderenText"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { onMounted, ref } from "vue";
import TipObject from "./TipObject.vue";
import {
  tipsExplainTextHolder,
  tipsBottomAboutTextHolder,
  contactSirrisTextHolder,
  CirculairVlaanderenTextHolder,
} from "@/texts";
import { router } from '@/router';

export default {
  name: "TipsPage",
  components: {
    TipObject,
  },
  setup() {
    const AboutTipsText = tipsExplainTextHolder;
    const AboutBottomText = tipsBottomAboutTextHolder;
    const ContactSirrisText = contactSirrisTextHolder;
    const CirculairVlaanderenText = CirculairVlaanderenTextHolder;
    const tips = ref([]); // Declare tips as a ref for reactivity

    // Function to load the tips from JSON file
    const loadTips = async () => {
      try {
        const response = await fetch("/tips.json"); // Load JSON from public folder
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json(); // Parse the JSON
        tips.value = data; // Assign the data to the tips array
      } catch (error) {
        console.error("Error loading tips:", error);
      }
    };
    const setRooter = (name: string) => {
      router.push("/" + name);
    };

    // Use onMounted to load the JSON when the component is mounted
    onMounted(() => {
      loadTips(); // Load tips on component mount
    });
    return {
      setRooter,
      tips,
      AboutTipsText,
      AboutBottomText,
      ContactSirrisText,
      CirculairVlaanderenText,
    };
  },
};
</script>

<style scoped>
.tipsPage {
  position: absolute;
  width: 1140px;
  height: 100%;
  left: 50%;
  overflow-y: auto;
  background-color: #eaf2f2;
  transform: translate(-50%, 0);
}


.tipsPage::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar for Chrome, Safari, and Opera */
}
.tipsPage .gamebutton {
  position: absolute;
  background-image: url("@/assets/Button.png");
  background-size: 100% 100%;
  height: 50px;
  width: 192px;
  right: 32px;
  bottom: 20px;
  top: 50px;
  cursor: pointer;
}
.tipsPage .button-text {
  color: #ffffff;
  font-family: "Inter-SemiBold", Helvetica;
  font-size: 20px;
  font-weight: 600;
  left: 27px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 10px;
  width: 138px;
  user-select: none;
}

.tipsPage .toptext {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 1140px;
  height: auto;
  margin-top: 50px;
}

.tipsPage .toptext .title {
  color: #f15a24;
  font-family: SUSE;
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: 22px;
  /* 45.833% */
  text-align: left;
  width: 800px;
  height: 48px;
  flex-shrink: 0;
}

.tipsPage .toptext .subTitle {
  text-align: left;

  color: #fe9670;
  font-family: SUSE;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  /* 68.75% */
  width: 800px;
  height: 48px;
  flex-shrink: 0;
}

.tipsPage .toptext .ExplainText {
  color: #086f6f;
  font-family: SUSE;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 800px;
  height: auto;
  flex-shrink: 0;
  text-align: left;
  margin-bottom: 20px;
}

.tipsPage .divider {
  width: 1140px;
  height: 1px;
  background-color: #086f6f;
  margin-top: 83px;
  margin-bottom: 42px;
}

.tipsPage .tips {
  display: flex;
  flex-direction: column;
  /* Stack the tips vertically */
  align-items: center;
  /* Optional: Center the tips horizontally */
  width: 1140px;
}

.tipsPage .tip-object {
  position: relative;
  /* Changed from absolute to relative */
  width: 100%;
  height: auto;
  /* Allow the height to adjust based on content */
  margin: 0 auto;
  /* Center horizontally */
  padding-top: 20px;
}

.tipsPage .bottomContainer {
  position: absolute;
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* Two columns of equal width */
  gap: 40px;
  /* Space between the columns */
  width: 100%;
  /* Full width */
  max-width: 1140px;
  left: 50%;
  /* Center horizontally */
  transform: translate(-50%, 0);
}

.tipsPage .bottomContainer .text {
  color: #086f6f;
  text-align: left;
  font-family: SUSE;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.tipsPage .bottomContainer .bottomtitle {
  color: #086f6f;
  font-family: SUSE;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  /* 110% */
  text-align: left;
}

.tipsPage .bottomContainer .textContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.tipsPage .bottomContainer .containerContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 70px;
}
@media (max-width: 1140px) {
  .tipsPage {
   width: 850px;
   
  }
  .tipsPage .tip-object {
    width: 850px;
  }
}
</style>
