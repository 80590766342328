import LandingPage from '@/components/LandingPage.vue';
import TipsPage from '@/components/TipsPage.vue';
import UnityGame from '@/components/UnityGame.vue';
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        path: '/Game',
        name: 'Game',
        component: UnityGame
    },
    {
        path: '/tips',
        name: 'Tips',
        component: TipsPage
    },

    {
        path: '/',
        name:'LandingPage',
        component: LandingPage
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/'
    },

];
export const router = createRouter({
    history: createWebHistory(),
    routes
});

