import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "topContainer" }
const _hoisted_2 = { class: "horizContainer" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "titleGroup" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "text" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "tip-object",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleExpand && _ctx.toggleExpand(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("img", {
          src: _ctx.tipImage,
          alt: "Placeholder image"
        }, null, 8, _hoisted_3),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.title), 1),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.description), 1),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "chevron" }, null, -1))
        ])
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["longTextColumns", { expanded: _ctx.expanded }])
    }, [
      _createElementVNode("div", {
        class: "text",
        innerHTML: _ctx.firstHalf
      }, null, 8, _hoisted_7),
      _createElementVNode("div", {
        class: "text",
        innerHTML: _ctx.secondHalf
      }, null, 8, _hoisted_8)
    ], 2)
  ]))
}