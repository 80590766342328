<template>
  <div class="landing-page">
    <div class="background">
      <div class="cards" alt="Cards" />
      <div class="poster" alt="Poster" />
    </div>
    <div id="textcards">
      <div class="buttonCards">
        <TextElement v-if="gameAboutText && gameplaytext" :initialHeight="457" title="RoboMove inc."
          :subTitle="gameAboutText" :textContent="gameplaytext" :marginBottom="0" button="SPEEL HET SPEL" />
        <TextElement v-if="toTipsText" :initialHeight="198" :subTitle="toTipsText" button="NAAR DE TIPS"
          :marginTop="20" />

      </div>
      <TextElement v-if="LivingLabText && AboutProjectText" :initialHeight="676"
        title="Living Lab Circular Machine Building" :subTitle="LivingLabText" :textContent="AboutProjectText" />
    </div>
    <LogosComponent />
  </div>
</template>

<script lang="ts">
import { router } from "@/router";
import {
  AboutProjectTextHolder,
  LivingLabTextHolder,
  gameAboutTextHolder,
  gameHowToPlayTextHolder,
  toTipsTextHolder,
} from "@/texts";
import { defineComponent, ref, onMounted } from "vue";
import LogosComponent from "./LogosComponent.vue";
import TextElement from "./textElement.vue";

export default defineComponent({
  name: "LandingPage",
  components: {
    LogosComponent,
    TextElement
  },
  setup() {
    // Define as reactive references
    const AboutProjectText = ref('');
    const LivingLabText = ref('');
    const gameAboutText = ref('');
    const gameplaytext = ref('');
    const toTipsText = ref('');

    // Set the values when the component is mounted
    onMounted(() => {
      AboutProjectText.value = AboutProjectTextHolder;
      LivingLabText.value = LivingLabTextHolder;
      gameAboutText.value = gameAboutTextHolder;
      gameplaytext.value = gameHowToPlayTextHolder;
      toTipsText.value = toTipsTextHolder;


    });

    const setRooter = (name: string) => {
      router.push("/" + name);
    };

    return {
      setRooter,
      AboutProjectText,
      LivingLabText,
      gameAboutText,
      gameplaytext,
      toTipsText,
    };
  },
});
</script>


<style>
.landing-page {

  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;

}

.landing-page .background {
  background-size: cover;
  background-image: url("@/assets/background-wood.jpg");
  background-repeat: repeat-y;
  height: auto;
  position: relative;
  width: 100%;
}

.landing-page .cards {
  background-image: url("@/assets/Cards.png");
  height: 735px;
  right: 0px;
  object-fit: cover;
  position: absolute;
  top: 164px;
  width: 491px;
}

.landing-page .poster {
  position: absolute;

  background-image: url("@/assets/poster.png");
  background-size: contain;
  /* Ensure the image scales to fit its container */
  background-repeat: no-repeat;
  /* Prevent the image from repeating */
  height: 80%;
  /* Set height to 80% of the container */
  width: 80%;
  /* Set width to 80% of the container */
  left: 4%;
  /* Adjust left position to center the smaller poster */
  top: 10%;
  /* Adjust top position as needed */
}

#textcards {
  position: absolute;
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* Two columns of equal width */
  justify-content: space-between;
  width: 1183px;
  height: 100%;
  max-height: calc(100vh - 110px);
  /* Ensure it does not exceed the screen height minus the space for the logos */
  overflow-y: auto;
  /* Make it scrollable */
  overflow-x: hidden;
  left: 50%;
  transform: translateX(-50%);
  gap: 20px;
  /* Add 20 pixels of space between the grid items */
}

#textcards::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar for Chrome, Safari, and Opera */
}

@media (max-width: 1140px) {
  #textcards {
    width: 580px;
    grid-template-columns: 1fr;
    /* Single column layout */
    grid-row-gap: 20px;
    /* Add some space between the rows */
    left: 50%;
    /* Center horizontally */
    max-height: calc(100vh - 110px);
    /* Adjust max-height for smaller screens */
    overflow-y: auto;
    /* Ensure scrollable */
  }

  #textcards::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar for Chrome, Safari, and Opera */
  }

  .landing-page .buttonCards {

    flex-direction: column;
    justify-content: space-between;
    margin-top: 50px;
  }


}

@media (max-width:1800) {

  #textcards {
    position: fixed;
    transform: translate(0, 400)
  }
}

@media (max-width: 700px) {
  #textcards {
    width: 300px;

  }
}
</style>
