import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "card-background" }
const _hoisted_2 = { class: "textWrapper" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "uitleg-project",
    style: _normalizeStyle({ height: _ctx.initialHeightRef + 'px', marginTop: _ctx.marginTopRef + 'px', marginBottom: _ctx.marginBottomRef + 'px' })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.titleContentRef)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "main-titel",
              innerHTML: _ctx.titleContentRef
            }, null, 8, _hoisted_3))
          : _createCommentVNode("", true),
        (_ctx.subTitleContentRef)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "uitlegtitel",
              innerHTML: _ctx.subTitleContentRef
            }, null, 8, _hoisted_4))
          : _createCommentVNode("", true),
        (_ctx.textContentRef)
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: "regularText",
              innerHTML: _ctx.textContentRef
            }, null, 8, _hoisted_5))
          : _createCommentVNode("", true),
        (_ctx.buttonRef)
          ? (_openBlock(), _createElementBlock("button", {
              key: 3,
              class: "custom-button",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setRooter(_ctx.buttonRef)))
            }, _toDisplayString(_ctx.buttonRef), 1))
          : _createCommentVNode("", true)
      ])
    ])
  ], 4))
}