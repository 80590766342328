import { defineComponent } from 'vue';

<template>
  <div class="logos">
    <div class="container">
      <a :href="links.DRK" target="_blank" style="margin: 0 0px;">
        <img :src="images.DRK" alt="DRK Logo" />
      </a>
      <a :href="links.Siris" target="_blank" style="margin: 0 0px;">
        <img :src="images.Siris" alt="Siris Logo" />
      </a>
      <a :href="links.Voka" target="_blank" style="margin: 0 0px;">
        <img :src="images.Voka" alt="Voka Logo" />
      </a>
      <a :href="links.EU" target="_blank" style="margin: 0 0px;">
        <img :src="images.EU" alt="EU Logo" />
      </a>
      <a :href="links.VLAIO" target="_blank" style="margin: 0 0px;">
        <img :src="images.VLAIO" alt="VLAIO Logo" />
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "LogosComponent",
  setup() {
    const images = {
      DRK: new URL("@/assets/DRK.png", import.meta.url).href,
      Siris: new URL("@/assets/Siris.png", import.meta.url).href,
      Voka: new URL("@/assets/Voka.png", import.meta.url).href,
      EU: new URL("@/assets/EU.png", import.meta.url).href,
      VLAIO: new URL("@/assets/Vlaio.png", import.meta.url).href,
    };
    const links = {
      DRK: "https://designregio-kortrijk.be/",
      Siris: "https://www.sirris.be/",
      Voka: "https://www.voka.be/west-vlaanderen",
      EU: "https://next-generation-eu.europa.eu/",
      VLAIO:
        "https://www.vlaio.be/nl/begeleiding-advies/expertisedatabank/vlaanderen-circulair",
    };
    const hideComponent = ()=> {
      const logosElement = document.querySelector('.logos');
      console.log(logosElement);
      if (logosElement) {
        logosElement.classList.add('hide');

      }
    }

    return { images, links,hideComponent };
  },
  methods: {
    
  },
});
</script>

<style scoped>
.logos {
  background-color: #ffffff;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  height: 115px;
  left: 50%;
  transform: translate(-50%, 0);
  position: fixed;
  bottom: 0;
  width: 1183px;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.25);
  z-index: 9999;
}
@keyframes moveDown {
  from {
    bottom: 0;
  }
  to {
    bottom: -115px;
  }
}

.logos.hide {
  animation: moveDown 2s forwards;
}

.logos .container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-left:80px;
  padding-right: 80px;
  box-sizing: border-box;
  gap: 10px; /* Adds space between images */
}

.logos img {
  height: 100px; /* Set a consistent height for all images */
  width: auto; /* Maintain aspect ratio by allowing width to adjust */
  object-fit: contain; /* Ensure the image scales without distortion */
  max-width: 80%; /* Prevent the images from exceeding the container's width */
  flex-shrink: 1; /* Allow images to shrink to fit if needed */
  padding: 0 5px; /* Add padding between images */
}
@media (max-width: 1140px) {
  .logos {
    background-color: #ffffff;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    height: 115px;
    left: 50%;
    transform: translate(-50%, 0);
    position: fixed;
    bottom: 0;
    width: 580px;
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.25);
    z-index: 9999;
  }
}
</style>
