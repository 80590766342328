<template>
  <div class="tip-object" @click="toggleExpand">
    <div class="topContainer">
      <div class="horizContainer">
        <img :src="tipImage" alt="Placeholder image" />
        <div class="titleGroup">

          <div class="title">{{ title }}</div>
          <div class="text">{{ description }}</div>
          <div class="chevron"></div>
          <!--           <div class="divider"></div>
 -->
        </div>
      </div>

    </div>

    <!-- Expanded part, height depends on the text content -->
    <div class="longTextColumns" :class="{ expanded: expanded }">
      <div class="text" v-html="firstHalf"></div>
      <div class="text" v-html="secondHalf"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, toRefs, onMounted, defineComponent } from "vue";

export default defineComponent({
  name: "TipObject",
  props: {
    title: String,
    description: String,
    textContent: String, // New prop to accept the long text from the parent
    image: String,
  },
  setup(props: any) {
    const expanded = ref(false);
    const fullText = ref(props.textContent); // The full text passed from parent
    const firstHalf = ref(""); // First half of the text
    const secondHalf = ref(""); // Second half of the text
    const { image } = toRefs(props);
    // eslint-disable-next-line
    const tipImage = require(`@/assets/${image.value}`);

    const toggleExpand = () => {
      expanded.value = !expanded.value;
    };

    const splitTextInHalf = () => {
      const totalTextLength = fullText.value.length;
      const middleIndex = Math.floor(totalTextLength / 2);

      // Find the nearest sentence-ending punctuation mark (., !, ?) after the middle index
      let splitPoint = middleIndex;
      const punctuationMarks = [".", "!", "?"];

      // Look for a punctuation mark after the middle index
      while (
        splitPoint < fullText.value.length &&
        !punctuationMarks.includes(fullText.value[splitPoint])
      ) {
        splitPoint++;
      }

      // If no punctuation mark is found, fallback to the nearest space
      if (splitPoint >= fullText.value.length) {
        splitPoint = middleIndex;
        while (
          splitPoint < fullText.value.length &&
          fullText.value[splitPoint] !== " "
        ) {
          splitPoint++;
        }
      }

      // Include the punctuation mark in the first half and trim any leading spaces in the second half
      firstHalf.value = fullText.value.slice(0, splitPoint + 1).trim();
      secondHalf.value = fullText.value.slice(splitPoint + 1).trim();
    };


    onMounted(() => {
      splitTextInHalf(); // Split the text in half on mount
    });

    return {
      toggleExpand,
      tipImage,
      expanded,
      firstHalf,
      secondHalf,
    };
  },
});
</script>

<style scoped>
.tip-object {
  position: relative;
  border-radius: 18px;
  background-color: #ffffff;
  height: 140px;
  cursor: pointer;
  overflow: hidden;
  transition: max-height 0.5s ease;
  margin: 0 auto;
  width:50%;
  padding-top: 20px;

  /* Animate the expansion/collapse */
  box-sizing: border-box;
}


.tip-object .topContainer {
  display: flex;
  /* Flexbox for image and text alignment */
  align-items: flex-start;
  gap: 20px;
  /* Space between image and text */
}

.tip-object .topContainer .horizContainer {
  display: flex;
  /* Flexbox for horizontal alignment */
  align-items: flex-start;
  gap: 30px;
  /* Space between image and text */
}

.tip-object .topContainer img {
  height: 100px;
  width: 100px;
  left: 10px;
  bottom: 10px;
  object-fit: contain;
  flex-shrink: 0;
  position: relative;

  /* Prevent image from shrinking */
}

.tip-object .topContainer .chevron {
  position: relative;
  background-image: url('@/assets/chevron-left-solid 1.svg');
  top: 20px;
  height: 12px;
  width: 19px;
  object-fit: contain;
  flex-shrink: 0;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
}

.tip-object .topContainer .divider {
  width: 100%;
  height: 1px;
  position: relative;
  top: 40px;
  background-color: #086f6f;
}

.tip-object .titleGroup {
  flex-grow: 1;
}

.tip-object .title {
  color: #086f6f;
  text-align: left;
  font-family: SUSE;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 5px;
}

.tip-object .text {
  color: #086f6f;
  text-align: left;
  font-family: SUSE;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.tip-object .longTextColumns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* Two columns of equal width */
  gap: 50px;
  /* Space between the columns */
  margin-left: 120px;
  margin-right: 70px;
  max-height: 0;
  /* Initially hidden */
  overflow: hidden;
  transition: max-height 0.5s ease;
  /* Smooth height transition */
}


.tip-object .longTextColumns .text {
  color: #086f6f;
  text-align: left;
  font-family: SUSE;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 40px;
}

.tip-object .longTextColumns.expanded {
  max-height: 500px;
  /* Large enough to accommodate content when expanded */
}

@media (max-width: 1140px) {
  .tip-object  {
    width: 850px;
  }
  .tip-object .topContainer {
    width: 850px;

  }
  .tip-object .longTextColumns  {
    width: 850px;
    gap:20px;
  
  }
  .tip-object .longTextColumns .text {
    width: 250px;
  }
}
</style>
