import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "landing-page" }
const _hoisted_2 = { id: "textcards" }
const _hoisted_3 = { class: "buttonCards" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextElement = _resolveComponent("TextElement")!
  const _component_LogosComponent = _resolveComponent("LogosComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "background" }, [
      _createElementVNode("div", {
        class: "cards",
        alt: "Cards"
      }),
      _createElementVNode("div", {
        class: "poster",
        alt: "Poster"
      })
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.gameAboutText && _ctx.gameplaytext)
          ? (_openBlock(), _createBlock(_component_TextElement, {
              key: 0,
              initialHeight: 457,
              title: "RoboMove inc.",
              subTitle: _ctx.gameAboutText,
              textContent: _ctx.gameplaytext,
              marginBottom: 0,
              button: "SPEEL HET SPEL"
            }, null, 8, ["subTitle", "textContent"]))
          : _createCommentVNode("", true),
        (_ctx.toTipsText)
          ? (_openBlock(), _createBlock(_component_TextElement, {
              key: 1,
              initialHeight: 198,
              subTitle: _ctx.toTipsText,
              button: "NAAR DE TIPS",
              marginTop: 20
            }, null, 8, ["subTitle"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.LivingLabText && _ctx.AboutProjectText)
        ? (_openBlock(), _createBlock(_component_TextElement, {
            key: 0,
            initialHeight: 676,
            title: "Living Lab Circular Machine Building",
            subTitle: _ctx.LivingLabText,
            textContent: _ctx.AboutProjectText
          }, null, 8, ["subTitle", "textContent"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_LogosComponent)
  ]))
}