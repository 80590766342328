import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "tipsPage" }
const _hoisted_2 = { class: "gamebutton" }
const _hoisted_3 = { class: "toptext" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "tips" }
const _hoisted_6 = { class: "bottomContainer" }
const _hoisted_7 = { class: "textContainer" }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "containerContainer" }
const _hoisted_10 = { class: "textContainer" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = { class: "textContainer" }
const _hoisted_13 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TipObject = _resolveComponent("TipObject")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "button-text",
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.setRooter('Game')))
      }, "Speel het spel")
    ]),
    _createElementVNode("div", _hoisted_3, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "title" }, "Learning cards", -1)),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "subTitle" }, " 16 tips om de transitie naar PaaS haalbaar te maken ", -1)),
      _createElementVNode("div", {
        class: "ExplainText",
        innerHTML: $setup.AboutTipsText
      }, null, 8, _hoisted_4)
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.tips, (tip, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "tip-object",
          key: index
        }, [
          _createVNode(_component_TipObject, {
            title: tip.title,
            description: tip.description,
            textContent: tip.text,
            image: tip.image
          }, null, 8, ["title", "description", "textContent", "image"])
        ]))
      }), 128))
    ]),
    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "divider" }, null, -1)),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "bottomtitle" }, " Over Circular Machine Building en de businessgame ", -1)),
        _createElementVNode("div", {
          class: "text",
          innerHTML: $setup.AboutBottomText
        }, null, 8, _hoisted_8)
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "bottomtitle" }, " Over het businessmodel product-as-a-service ", -1)),
          _createElementVNode("div", {
            class: "text",
            innerHTML: $setup.ContactSirrisText
          }, null, 8, _hoisted_11)
        ]),
        _createElementVNode("div", _hoisted_12, [
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "bottomtitle" }, " Over de circulaire ambities van Vlaanderen ", -1)),
          _createElementVNode("div", {
            class: "text",
            innerHTML: $setup.CirculairVlaanderenText
          }, null, 8, _hoisted_13)
        ])
      ])
    ])
  ]))
}