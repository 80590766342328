export const AboutProjectTextHolder = `
      <p>
        In het project hebben de machinebouwers onderzocht of het businessmodel 
        ‘<strong>product-as-a-service</strong>’ ook voor hun specifieke machines kon worden uitgerold.
        Er werd geëxperimenteerd, getest en uitgewerkt. De machinebouwers konden hierbij 
        beroep doen op experts en ze wisselden regelmatig ideeën en tips uit.
      </p>
      <p>
        In de businessgame hebben we hun ervaringen en hun algemene leerlessen verwerkt. Voor de 
        learning cards in het business game deden we verder ook beroep op de studie 
        "<strong>Verdienstelijkingsmodellen in de Circulaire Economie</strong>" van Arteveldehogeschool uit 2023.
      </p>
    `;
export const LivingLabTextHolder = `
      <p>
        Het Living Lab Circular Machine Building werd in 2023 en 2024 georganiseerd door  
        <strong>Designregio Kortrijk, Sirris, Voka West-Vlaanderen</strong> 
        en 4 machinebouwers: <strong>Vintiv, Cyago, Eliet</strong> en <strong>3D Tools.</strong>
      </p>
    `;

export const gameAboutTextHolder = `
      <p>
       Slaag je er in om tijdig de transitie naar een duurzamer business model te maken, binnen budget en met tevreden klanten?
      </p>
    `;
export const gameHowToPlayTextHolder = `
        <p> Doel van de businessgame is aan geïnteresseerde machinebouwers een aantal knipperlichten meegeven; aandachtspunten die cruciaal zijn om het product-as-a-service businessmodel succesvol aan te pakken.</p>
      <p>De game werd ontwikkeld in samenwerking met <a href="http://www.daestudios.be" style="font-weight: bold; text-decoration: underline; color: inherit;">DAE Studios</a>.</p>

      `;
export const toTipsTextHolder = `
      <p>Krijg tips om de circulaire stap te nemen binnen uw project zonder te spelen.</p>
      `;
export const tipsExplainTextHolder = `<p>Ben je een machinebouwer en geïnteresseerd in het product-as-a-service businessmodel? </p>
<p></p>
<p>Hier vind je <strong>aandachtspunten</strong> die je niet over het hoofd mag zien en die cruciaal zijn om het product-as-a-service businessmodel succesvol aan te pakken.</p>
<p></p>
<p>We deden hiervoor ook beroep op de studie “Verdienstelijkingsmodellen in de Circulaire Economie” van Arteveldehogeschool uit 2023.</p>`;

export const tipsBottomAboutTextHolder = `
<p>
        Dit businessgame werd ontwikkeld in het kader van het <strong>Living Lab Circular Machine Building</strong> dat in 2023 en 2024 werd georganiseerd door  
        <strong>Designregio Kortrijk, Sirris, Voka West-Vlaanderen</strong> 
        en 4 machinebouwers: <strong>Vintiv, Cyago, Eliet</strong> en <strong>3D Tools</strong>
      </p>
      <p></p>
       <p>
        In de businessgame hebben we hun ervaringen en hun algemene leerlessen verwerkt. Voor de 
        learning cards in het business game deden we verder ook beroep op de studie 
        "<strong>Verdienstelijkingsmodellen in de Circulaire Economie</strong>" van Arteveldehogeschool uit 2023.
      </p>
        <p></p>
         <p> Doel van de businessgame is aan geïnteresseerde machinebouwers een aantal knipperlichten meegeven; aandachtspunten die cruciaal zijn om het product-as-a-service businessmodel succesvol aan te pakken.</p>
      <p>De game werd ontwikkeld in samenwerking met <a href="http://www.daestudios.be" style="font-weight: bold; text-decoration: underline; color: inherit;">DAE Studios</a>.</p>`;
export const contactSirrisTextHolder = `
<p>In het product-as-a-service businessmodel worden producten niet verkocht maar aangeboden als een dienst. Na afloop keren producten terug naar de producent, waarna ze opnieuw ingezet en gevaloriseerd worden. Dankzij een hogere benuttingsgraad en langere levensduur van producten wordt het gebruik van grondstoffen en energie gereduceerd. Tegelijk wordt met de dienstverlening rond het product een meerwaarde gerealiseerd bij klanten.</p>
<p></p>
Heb je vragen hoe dit zich vertaalt naar de specifieke situatie van jouw bedrijf? Contacteer: <a href="mailto:circular@sirris.be"style="font-weight: bold; text-decoration: underline; color: inherit;">circular@sirris.be</a>
<p></p>
Heb je vragen over ecodesign? Contacteer: <a href="stan@designregio-kortrijk.be"style="font-weight: bold; text-decoration: underline; color: inherit;">stan@designregio-kortrijk.be</a>
<p></p>
Voor vragen over het design van take-back en retourprocessen. Contacteer: <a href="marijke@designregio-kortrijk.be"style="font-weight: bold; text-decoration: underline; color: inherit;">marijke@designregio-kortrijk.be</a>
`
export const CirculairVlaanderenTextHolder = `
<p>In <a href="https://www.vlaio.be/nl/begeleiding-advies/expertisedatabank/vlaanderen-circulair" style="font-weight: bold; text-decoration: underline; color: inherit;">het Vlaams Energie en Klimaatplan</a> wordt de ambitie vooropgesteld om onze materiaalvoetafdruk tegen 2030 met 30% te verminderen en om onze broeikasgasuitstoot tegen 2030 drastisch te verminderen.</p>
<p></p>
<p>Daarom subsidieert de Vlaamse overheid living lab projecten waarbij circulaire systeemoplossingen worden uitgewerkt, getest, geïmplementeerd , en opgeschaald.</p>

`