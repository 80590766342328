import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "logos" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = ["href"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["href"]
const _hoisted_6 = ["src"]
const _hoisted_7 = ["href"]
const _hoisted_8 = ["src"]
const _hoisted_9 = ["href"]
const _hoisted_10 = ["src"]
const _hoisted_11 = ["href"]
const _hoisted_12 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        href: _ctx.links.DRK,
        target: "_blank",
        style: {"margin":"0 0px"}
      }, [
        _createElementVNode("img", {
          src: _ctx.images.DRK,
          alt: "DRK Logo"
        }, null, 8, _hoisted_4)
      ], 8, _hoisted_3),
      _createElementVNode("a", {
        href: _ctx.links.Siris,
        target: "_blank",
        style: {"margin":"0 0px"}
      }, [
        _createElementVNode("img", {
          src: _ctx.images.Siris,
          alt: "Siris Logo"
        }, null, 8, _hoisted_6)
      ], 8, _hoisted_5),
      _createElementVNode("a", {
        href: _ctx.links.Voka,
        target: "_blank",
        style: {"margin":"0 0px"}
      }, [
        _createElementVNode("img", {
          src: _ctx.images.Voka,
          alt: "Voka Logo"
        }, null, 8, _hoisted_8)
      ], 8, _hoisted_7),
      _createElementVNode("a", {
        href: _ctx.links.EU,
        target: "_blank",
        style: {"margin":"0 0px"}
      }, [
        _createElementVNode("img", {
          src: _ctx.images.EU,
          alt: "EU Logo"
        }, null, 8, _hoisted_10)
      ], 8, _hoisted_9),
      _createElementVNode("a", {
        href: _ctx.links.VLAIO,
        target: "_blank",
        style: {"margin":"0 0px"}
      }, [
        _createElementVNode("img", {
          src: _ctx.images.VLAIO,
          alt: "VLAIO Logo"
        }, null, 8, _hoisted_12)
      ], 8, _hoisted_11)
    ])
  ]))
}